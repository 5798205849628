.calendar-wrapper {
  width: 100%;
}

.calendar-month {
  width: 100%;
  padding-bottom: 0px;
  padding-left: 0px;
}

.calendar-next-prev-button {
  margin: 0;
}

.calendar-month-picker {
  margin: 0;
}

.calendar-date-display-wrapper {
  display: none;
}

.calendar-month-and-year-wrapper {
  padding: 10px 10px 0 10px;
}